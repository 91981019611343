.servicios {
  // min-height: calc(100vh - var(--header-height));
  padding-top: calc(var(--header-height) * 1);
  padding-bottom: calc(var(--header-height) * 1);
  background-color: var(--color-clear);

  .container {
    height: auto;
  }

  &--item {
    position: relative;
    font-family: var(--font-main);
    font-size: 1.5em;
    line-height: 1;
    text-transform: uppercase;
    padding: 1.25em 0;
    border-bottom: solid var(--color-jorodark) 1px;
    hyphens: auto;
    display: flex;
    align-items: center;

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      // height: 100%;
      height: 0;
      width: 100%;
      transform: translateY(-50%);
      // transform: scaleY(0);
      background-color: var(--color-jorored);
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      hyphens: auto;
      overflow: hidden;
      // mix-blend-mode: difference;
    }

    &:hover {
      .mask {
        // transform: scaleY(102%);
        // transform-origin: center;
        height: 102%;
        color: white;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    p {
      span {
        color: var(--color-jorored);
        font-weight: 700;
      }
    }

    &__bulletContainer {
      position: relative;
      flex-shrink: 0;
    }

    &__bullet {
      // display: none;
      flex-shrink: 0;
      --size: 3rem;
      height: var(--size);
      width: var(--size);
      margin-right: 1rem;

      &.deep {
        position: absolute;
        border: solid red;
        top: 0;
        left: 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &__textContainer {
      position: relative;

      p.deep {
        color: red;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
