.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-jorored);
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: 1s ease-in-out;

  img {
    width: 100%;
    transform: translateY(-10%);
  }

  p {
    font-size: 1.5em;
    font-family: var(--font-main);
    color: var(--color-jorodark);
  }

  &.bye {
    top: -100%;
    // transform: scaleY(0);
    // transform-origin: top;

    // left: -100%;
  }
}
