body {
  font-size: 16px;
  font-family: "Neue Haas Display", sans-serif;
  color: var(--color-jorodark);
  overflow-x: hidden;
}

.noscroll {
  overflow: hidden;
  height: 100vh;
}

.container {
  margin: 0 auto;
  // max-width: $container-maxwidth;
  height: 100%;
  padding: 0 var(--container-padding);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--color-jorodark);
}

h1,
h2,
h3 {
  font-family: var(--font-main);
}

canvas.glsl {
  // position: fixed;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // border: solid green;
  z-index: -1;
}

@media screen and (max-height: 600px) {
  body {
    font-size: 14px;
  }
}
