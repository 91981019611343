.medida {
  min-height: 100vh;
  background-color: var(--color-jorodark);
  position: relative;

  .container {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: transparent;
  }

  .container-half {
    height: 40vh;
  }

  &--sketch {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &--text {
    color: var(--color-jorored);
    font-size: 2.5em;
    font-weight: 700;
    width: 7em;
    margin-left: 0.5em;
    margin-bottom: 1em;
    overflow: hidden;
    line-height: 1;

    span {
      color: var(--color-clear);
    }
  }

  &--splitline {
    overflow: hidden;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .medida {
    &--text {
      font-size: 4em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .medida {
    &--text {
      width: 9em;
    }
  }
}
