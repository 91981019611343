.mobile_nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  background-color: white;
  z-index: 666;
  // mix-blend-mode: overlay;
  // border: solid red;

  &--brand {
    width: 5em;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .mobile_nav {
    display: none;
  }
}
