nav {
  // display: none;
  // height: var(--header-height);
  position: fixed;
  top: 0;
  right: 0;
  // width: 100%;
  // border: solid purple;
  font-family: var(--font-main);
  font-size: 0.7em;
  color: var(--color-clear);
  mix-blend-mode: difference;
  z-index: 666;

  ul {
    background-color: transparent;
    padding: 1.5em 0;

    li {
      // margin-bottom: 0.5em;
      margin-bottom: 1em;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        font-family: var(--font-main);
        color: var(--color-clear);
        mix-blend-mode: difference;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-small}*1em)) {
  nav {
    color: blue;
  }
}
@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  nav {
    color: orange;

    a {
      color: orange;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  nav {
    color: green;

    a {
      color: green;
    }
  }
}

@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  nav {
    color: red;

    a {
      color: red;
    }
  }
}
