.tejemos {
  position: relative;
  height: 100vh;
  width: 100%;

  .container {
    position: relative;
    overflow: hidden;
  }

  &--canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &--title {
    color: var(--color-jorored);
    font-size: 2.4em;
    line-height: 1;
    font-weight: 700;
    position: absolute;
    top: 25%;
  }

  &--text {
    font-size: var(--text-size);
    font-weight: 500;
    width: 50%;
    color: var(--color-clear);
    position: absolute;
    bottom: 25%;
    right: 0;

    span {
      color: var(--color-jorored);
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .tejemos {
    &--title {
      font-size: 4em;
    }

    &--text {
      font-size: 1.5em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .tejemos {
    &--title {
      max-width: 12em;
      margin-left: 1em;
    }

    &--text {
      width: 25%;
    }
  }
}
