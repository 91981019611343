.footer {
  &--logo {
    border-top: solid var(--color-jorodark) 2px;

    img {
      margin: 2em 0;
      width: 100%;
    }
  }

  .container {
    height: auto;
  }

  &--mail {
    font-family: var(--font-main);
    font-size: 1.25em;
    margin-left: 2.25em;
    font-weight: 700;
  }

  &--item {
    a {
      display: flex;
      gap: 1em;
      cursor: pointer;
    }

    font-family: var(--font-main);
    font-size: 1.25em;
    margin-top: 1em;

    &:last-of-type {
      margin-bottom: 1em;
    }

    &__icon {
      --size: 1.5em;
      height: var(--size);
      width: var(--size);
      flex-shrink: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
