@font-face {
  font-family: "Kattelyn";
  src: url("/static/fonts/Kattelyn-Regular.woff2");
  font-weight: 400;
  font-style: "normal";
}

@font-face {
  font-family: "Monument";
  src: url("/static/fonts/Monument-Regular.woff2");
  font-weight: 400;
  font-style: "normal";
}

@font-face {
  font-family: "Monument";
  src: url("/static/fonts/Monument-Bold.woff2");
  font-weight: 700;
  font-style: "normal";
}
// @font-face {
//   font-family: "Monument";
//   src: url("/static/fonts/Monument-Black.woff2");
//   font-weight: 800;
//   font-style: "normal";
// }

@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Roman.woff");
  font-weight: 400;
  font-style: "normal";
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Medium.woff");
  font-weight: 500;
  font-style: "normal";
}
@font-face {
  font-family: "Neue Haas Display";
  src: url("/static/fonts/NeueHaasDisplay-Bold.woff");
  font-weight: 700;
  font-style: "normal";
}
