.contacto {
  background-color: var(--color-jorored);
  font-family: var(--font-main);
  padding: 1em 0;
  height: auto;

  .container {
    height: auto;
    display: grid;
    grid-template-rows: 10vh 1fr 10vh;
  }

  &--spider {
    font-size: 0.7em;
    width: 55%;
    grid-row: 1 / 2;
  }

  &--textContainer {
    grid-row: 2 / 3;
  }

  &--subtitle {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1;
  }

  &--title {
    color: var(--color-clear);
    font-size: 2.9em;
    line-height: 1;
    cursor: pointer;
  }

  &--arrow {
    grid-row: 3 / 4;
    margin-top: 0.5em;
    cursor: pointer;

    &__imgContainer {
      --size: 4em;
      height: var(--size);
      width: var(--size);
      margin-left: auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .contacto {
    padding: 0;

    .container {
      padding: 2rem var(--container-padding);
      display: block;
    }

    &--spider {
      width: 23em;
      font-size: 1em;
      line-height: 1;
    }

    &--subtitle {
      font-size: 2.5em;
      margin-top: 1em;
    }

    &--title {
      font-size: 5em;
    }

    &--arrow {
      &__imgContainer {
        --size: 5em;
      }
    }
  }
}
