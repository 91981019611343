.hero {
  height: 100vh;

  .container {
    position: relative;
  }

  &--title {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    color: var(--color-jorored);
    text-transform: uppercase;
    font-size: 3.5em;
    font-weight: 400;
    font-weight: 700;
    line-height: 1;
    user-select: none;
    overflow: hidden;
  }

  &--split {
    overflow: hidden;
  }

  &--strapline {
    position: absolute;
    bottom: 10%;
    width: 10em;
    font-weight: 700;
    right: var(--container-padding);

    span {
      color: var(--color-jorored);
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .hero {
    &--title {
      font-size: 5em;
      width: 8.3em;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
    }

    &--strapline {
      font-size: 1.5em;
    }
  }
}
