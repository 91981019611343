:root {
  // **************
  // COLORS
  // **************
  --color-joroblue: #192d46;
  --color-jorodark: #111111;
  --color-jorodark: #0d0d0d;
  --color-jorored: #fb6b60;
  --color-clear: #ffffff;

  // ****************
  // FONTS
  // ****************
  --font-main: "Monument", sans-serif;
  --font-text: "Neue Haas Display", sans-serif;

  // ****************
  // MISC
  // ****************
  --header-height: 3em;
  // --header-height: 40px;
  --container-padding: 1rem;
  // --text-size: 1.5em;
  --text-size: 1em;
}

// **************
// SASS VARIABLES
// **************
$bp-small: 24.15;
$bp-medium: 48; // 768
$bp-large: 60.25; // 964
$bp-xlarge: 75; // 1200

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  :root {
    --container-padding: 3rem;
    --header-height: 72px;
    --header-height: 54px;
  }
}
