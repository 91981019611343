.team {
  min-height: calc(100vh - var(--header-height));
  background-color: var(--color-jorodark);
  padding: 6em 0;

  .container {
    height: auto;
  }

  &--subtitle {
    color: var(--color-clear);
    font-weight: 400;
    line-height: 1;
    max-width: 12em;
  }

  &--title {
    margin-top: 1em;
    margin-bottom: 3em;
    color: var(--color-clear);
    font-size: 2.3em;
    line-height: 1.1;

    span {
      color: var(--color-jorored);
    }
  }

  &--list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &--member {
    flex-basis: 48%;
    flex-shrink: 0;
    gap: 1rem;
    margin-bottom: 2em;

    &__imgWrapper {
      height: 30vh;
      width: 100%;
      overflow: hidden;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.5s ease-out 50ms;
    }

    p {
      margin-top: 1em;
      color: var(--color-jorored);
      font-family: var(--font-main);
      font-weight: 500;
      font-size: 0.8em;
      text-align: center;

      &:last-of-type {
        margin-top: 0.5em;
        color: white;
        font-family: var(--font-text);
        font-weight: 400;
      }
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .team {
    &--member {
      flex-basis: 30%;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .team {
    .container {
      display: flex;
      justify-content: space-between;
    }

    .textContainer {
      flex-basis: 45%;
      flex-shrink: 0;
      gap: 2em;
    }

    &--title {
      max-width: 10em;
      font-size: 3.5em;
    }

    &--list {
      flex-basis: 55%;
    }
  }
}
